.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
div.fade.in.modal {
  display:flex !important;
}


div.DraftEditor-root {
  border: 1px solid #000;
  background-color: beige;
  /* height: 200px;
  width: 300px; */
  overflow-y: auto;
}

.hideElement{
    display: none;
}
.displayElement{
  display: inline;
}

.modal-dialog {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* width: 750px!important;
  height: 750px!important; */
  /* height: unquote("calc(100vh - 200px)"); */
  /* // 20px offset for the 10px margin on either side */
  
}


.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  outline: 0;
}

/* //https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Flexbox_skills */

/* for likes button */
.red {color: red}
.green {color: green}

.EditorDiv {
  border-style: solid;
  border-color: #92a8d1;
  padding: 5px;
  border-radius: 5px;
  text-align: left;
  border-width: thin;
}




/* div.DraftEditor-root {
  border: 1px solid #000;
  background-color: beige;
  height: 200px;
  width: 100%;
  overflow-y: auto;
  overflow-x:auto;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
} */





.CommentDiv {
  border-style: solid;
  border-color: #1b1c1d;
  padding: 10px;
  /* border-radius: 20px; */
  text-align: left;
}

.UserImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  height: 40%;
  border-radius: 50%;
  border: 1px solid #ddd;
  padding: 5px;
  
}


.avatar {
  vertical-align: middle;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display:inline;
}

nav ul {
  float: center;
  grid-row: 1;
  display: grid;
    /* grid-template-columns: 2fr 2fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr; */
    gap: 5px;
  max-width: 90%;
  list-style:none;
  padding-left: 1in;
  
  margin: 0;
  display: flex;
  align-items: stretch;
}

a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }

#myfiletable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#myfiletable td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#myfiletable tr:nth-child(even){background-color: #f2f2f2;}

#myfiletable tr:hover {background-color: #ddd;}

#myfiletable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
 

.flex-containerforbutton {
  /* display: flex; */
  display: grid;
  grid-template-columns: 50% 50%;
}
.flex-childbutton {
  flex: 1;
  border: 1px solid gray;  
  text-align:center;
}  


.flex-container {
  /* display: flex; */
  display: grid;
  grid-template-columns: 25% 75%;
}

.flex-child {
  flex: 1;
  border: 1px solid gray;  
  /* text-align:center; */
}  

.flex-child:first-child {
  margin-right: 0px;
} 



.followdt {
  flex-basis: 50%;
  padding: 2px 2px;
  background: rgb(227, 230, 224);
  text-align: right;
  color: rgb(31, 36, 25);
}
.followdd {
  /* flex-basis: 50%; */
  flex-grow: 1;
  margin: 0;
  padding: 2px 2px;
  border-bottom: 1px solid #333;
}


  .foralldl {
    display: flex;
    flex-flow: row wrap;
    border: solid #333;
    border-width: 1px 1px 0 0;
  }
 .formdt {
    flex-basis: 30%;
    padding: 2px 2px;
    background: rgb(227, 230, 224);
    text-align: right;
    color: rgb(31, 36, 25);
  }
  .formdd {
    flex-basis: 60%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 2px;
    border-bottom: 1px solid #333;
  }
  /* https://medium.com/frontend-canteen/the-magical-use-of-uncommon-labels-fieldset-and-legend-d3b29df4fb14 */

fieldset {
 
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border-right: 0px solid red;
  border-bottom: 0px solid red;
  margin-inline-start: 2px;
    margin-inline-end: 2px;
    padding-block-start:2px;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    padding-block-end: 2px;
  }

  fieldset.postEditor {
    width: 100%;
    
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border-width: 2px;
    border-right: 2px groove;
    border-bottom: 2px groove;
    margin-inline-start: 2px;
      margin-inline-end: 2px;
      padding-block-start:2px;
      padding-inline-start: 2px;
      padding-inline-end: 2px;
      padding-block-end: 2px;
      border-color: rgb(192, 192, 192);
    }
  fieldset.postitem {
    
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border-width: 2px;
    border-right: 2px groove;
    border-bottom: 2px groove;
    margin-inline-start: 2px;
      margin-inline-end: 2px;
      padding-block-start:2px;
      padding-inline-start: 2px;
      padding-inline-end: 2px;
      padding-block-end: 2px;
      border-color: rgb(192, 192, 192);
    }
  textarea:read-only {
    border: 0;
    box-shadow: none;
    background-color: rgb(190, 187, 187);
    width: 80%;
    float:center;
  }

textarea {
  /* font-size:20px; */
  width:85%;
  min-width: 150px;
}

.wrapper-div {
  display: flex;
  align-items: center;
  }

.ovalbutton {
  background-color: #8f8c8c;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  /* font-size: 16px; */
  margin: 4px 2px;
  opacity: 0.6;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  /* border-radius: 50%; */
}


.uploadbutton {
  /* background-color: #dfdddd; */
  /* border: none; */
  /* color: black; */
  padding: 8px 16px;
  text-align: center;
  /* font-size: 16px; */
  margin: 4px 2px;
  opacity: 0.6;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  /* border-radius: 50%; */
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.disablebutton {

  background-color: #c4c1c1c5;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  opacity: 0.6;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 50%;

}
nav a:link,
nav a:visited {
  background-color:rgb(206, 202, 202);
  border: 1px solid #eef1f1;
  border-radius: .5em;
  color: #fff;
  padding: .5em;
  display: inline-block;
  text-decoration: none;
  height: 60;
 
}
.box {
  margin: auto;
  width:60%;
  display: block;
  /* grid-template-columns: 50%;
  gap: 10px 2em; */
  
}

.boxforPhone {
  margin: auto;
  width:100%;
  display: block;
  /* grid-template-columns: 50%;
  gap: 10px 2em; */
  
}

.boxold {
  display: grid;
  grid-template-columns: 20% 50% 30%;
  gap: 10px 2em;
  
}
.imageLeft {
  float:left;
  width:36px;
  height:36px;
}
.linkTextMiddle {
  line-height: 1px;

}
.editorContainer {
  padding: 4em 10px 10px 10px;
  margin: 10px;
  height: 100%;
  position: relative;
}


.editorContainer {
  border: 1px blue solid!important;
  padding: 1em;
  margin: 1em;
}

.editors {
   border: 1px blue solid!important;
  padding: 20px 20px 20px 20px;
  margin: 1.25em;
  font-size: 1.1em;
  border-radius: 6px;
  text-align: left;
  line-height: 1.25em;
  color: black;
  min-height: 200px;
  position: relative;
}

.css-slib9c {
width: 100%!important;
}
  .buttonMenu {
  position: relative;
  display: flex;
  position: relative;
  justify-content: space-around;
  align-self: space-around;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* for video player */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

h1 {
  color: white;
}

img {
  max-width: 80%;
  max-height: 800px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

video {
  width: 100%px;
  height: 600px;
  max-width: 100%;
  max-height: 600px;
  display: inline;
  margin-left: auto;
  margin-right: auto;
}

.video-wrapper {
  width: 100%;
  height: 600px;
  max-width: 800px; 
  max-height: 600px;
  border-radius: 1px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.video-wrapper:hover .controls {
  transform: translateY(0%);
}
/* 
.controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 30px;
  padding: 14px;
  width: 100%;
  max-width: 500px;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.116);
  box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  transform: translateY(150%);
  transition: all 0.3s ease-in-out;
} */

.actions button {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.actions button i {
  background-color: none;
  color: white;
  /* font-size: 30px; */
}

input[type="range"] { 
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  height: 4px;
  width: 350px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 6px;
}

input[type="range"]::-moz-range-progress {
  background: white;
}

.velocity {
  appearance: none;
  background: #636464;
  color: white;
  outline: none;
  border: none;
  text-align: center;
  font-size: 16px;
}

.mute-btn {
  background: none;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
}

.mute-btn i {
  background-color: none;
  color: white;
  font-size: 20px;
}
footer{  position:relative;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: #efefef;
  text-align: center; }

  .css-9ds0c-MuiPaper-root-MuiDialog-paper  {
 
  width: 100%!important;
  }

  .rdw-image-modal {
    position: relative!important;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
}