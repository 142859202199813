/* //navbar.scss */
.navbar{
  margin: auto;  
  width: 60%;
    min-height: 6vh;
    box-sizing: border-box;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    background-color: #4169E1;
    transition: all .7s ease-in;
  }
  /* //IMPORTANT */
  .scrolled{
    margin:auto;
    position: fixed;
    top: 0;
    /* left: 0; */
    background-color: lightblue;
  }
  .logo img{
    width: 50px;
  }
      
  .navbardropdown {
    width: 100%;
    background-color: #555;
    overflow: auto;
  }

  .navbardropdown a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }

  .navbar a {
    float: left;
    font-size: 16px;
    color: white;
    text-align: center;
    /* padding: 14px 16px; */
    text-decoration: none;
  }
  
    
  .navbar a:hover,.navbardropdown a:hover .dropdown:hover .dropbtn {
    background-color: red;
  }
  
  
  .topnav {
   
    margin: auto;  
  /* width: 80%; */
    min-height: 6vh;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: gray;
    transition: all .7s ease-in;
  }
  
  .topnav a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 10px 10px;
    text-decoration: none;
    font-size: 17px;
  }
  
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }
  
  .topnav a.active {
    background-color: #04AA6D;
    color: white;
  }
  
  .topnav .icon {
    display: none;
  }
  
  @media screen and (max-width: 500px) {
    /* .topnav a:not(:first-child) {display: none;} */
    .topnav a.icon {
      float: right;
      display: block;
    }
  }
  
  @media screen and (max-width: 500px) {
    .topnav.responsive {position: relative;}
    .topnav.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
  }